import React from 'react';

import { SystemProps, x } from '@xstyled/emotion';

interface SpinnerProps extends SystemProps {
  active: boolean;
}

export const Spinner: React.FC<SpinnerProps> = ({ active, ...systemProps }) => {
  if (!active) {
    return null;
  }

  return (
    <x.div display="flex" justifyContent="center" alignItems="center">
      <x.div
        display="inline-block"
        w={5}
        h={5}
        border={4}
        borderColor={'white'}
        borderTopColor={'primary-600'}
        borderRadius="full"
        animation="spin"
        {...systemProps}
      />
    </x.div>
  );
};
