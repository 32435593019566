import { useAuth } from 'components/auth';
import { Dashboard } from 'components/layouts/Dashboard';
import { useSLAs, useUserInfo } from 'lib/stores';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { x } from '@xstyled/emotion';
import Error500 from 'components/Error500';
import { Spinner } from 'v2/components/atoms/Spinner';

export default function Index() {
  const ctx = useAuth(true);
  const router = useRouter();
  useSLAs();

  const { userInfo, loading, error: userInfoError } = useUserInfo();

  useEffect(() => {
    if (userInfo?.developer && userInfo.user_contracts.length > 0) {
      router.replace(
        '/developers/[developerId]/home',
        `/developers/${userInfo.developer.id}/home`
      );
      return;
    }

    if (userInfo?.agency_user) {
      router.replace(
        '/agencies/[agencyId]/home',
        `/agencies/${userInfo.agency_user.agencyId}/home`
      );
      return;
    }

    const client_user = userInfo?.client_users[0];
    if (client_user) {
      router.replace(
        '/clients/[clientId]/home',
        `/clients/${client_user.client.id}/home`
      );
      return;
    }

    if (userInfo?.admin) {
      router.replace(
        '/admins/[adminId]/home',
        `/admins/${userInfo.admin.id}/home`
      );
      return;
    }

    if (
      !userInfo ||
      (userInfo.developer && userInfo.user_contracts.length == 0)
    ) {
      router.replace('/developers/welcome', `/developers/welcome`);
      return;
    }

    //TODO: Implement `/clients/welcome` and `/agency/welcome` which are onboarding pages for clients and agencies respectively.
  }, [userInfo, router]);

  if (userInfoError) {
    return (
      <Dashboard>
        <Error500 error={userInfoError} />
      </Dashboard>
    );
  }

  const { user } = ctx;

  if (
    loading ||
    // Hack: Currently Auth Context can set the user but NOT properly set isLoading = false, causing infinite loading
    // isLoading ||
    !userInfo ||
    !user ||
    userInfo.client_users.length > 0 ||
    userInfo.developer ||
    userInfo.agency_user
  ) {
    return (
      <x.div
        h="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Spinner active={true} />
      </x.div>
    );
  }

  return <></>;
}
